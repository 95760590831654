import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import ChallengeSelect from './challengeSelect'
import CheckIn from './checkIn';
import { GlobalContext } from './GlobalContext';
import DndContext from './games/puzzle/components/DndContext';
import { motion, AnimatePresence } from "framer-motion";
import PuzzleGameContainer from './games/puzzle/PuzzleGameContainer';
import FirewallGameContainer from './games/firewall/FirewallGameContainer';
import CrackTheSafeGameContainer from './games/crack-the-safe/CrackTheSafeGameContainer';
import Test from './test';


export default function AnimatedPages() {
    const location = useLocation();
    // const {ShowHud, setShowHud} = useContext(GlobalContext);
    const { gameSessionData, showHud, setShowHud } = useContext(GlobalContext);
    const [ game, setGame] = useState(location.pathname.split('/').filter(Boolean)[0])

    // Manages showing the HUD for feedback
    const handleKeyDown = (event) => {
        if (event.key === '`') { 
            setShowHud((showHud) => !showHud); 
        }
      };
    
      useEffect(() => {
         window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    // console.log(location.pathname.split('/').filter(Boolean)[0])
    // console.log(game)

    return(
        <AnimatePresence><div className={game === 'puzzle' ? 'rotate-view' : ''}>
            { showHud && <ShowHud gameSessionData={gameSessionData} /> }
            <Routes key={location.pathname} location={location}>
                <Route path="/" element={<ChallengeSelect />} />

                <Route path="/:game" element={<CheckIn />} />

                {/* CHALLENGES */}
                <Route path="/puzzle/game" element={
                    <DndContext>
                        <PuzzleGameContainer />
                    </DndContext>
                    } />
                <Route path="/firewall/game" element={<FirewallGameContainer />} />
                <Route path="/crack-the-safe/game" element={<CrackTheSafeGameContainer />} />
                <Route path="/test" element={<Test />} />
            </Routes>
        </div></AnimatePresence>
    )
}

export function ShowHud({ gameSessionData }) {
    return(
        <motion.div key="hud" initial={{y: -80}} animate={{y: 0}} exit={{y: -80}} className='bg-white px-2 py-2 absolute w-screen shadow-md'>
            <div className='text-black text-sm'>
                <div>User: <i>{gameSessionData?.alias ? gameSessionData?.alias : 'undefined'}</i></div>
                <div>Session ID: <i>{gameSessionData?.gamesessionid ? gameSessionData?.gamesessionid : 'undefined'}</i></div>
                <div>RFID Tag ID: <i>{gameSessionData?.currentrfidtagid ? gameSessionData?.currentrfidtagid : 'undefined'}</i></div>
                <div className='text-xs'><i>Press the ~ key to hide the hud</i></div>
            </div>
        </motion.div>
    )
}