import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import pinContainerBackground from '../images/firewall-pin-container.svg'
import squareAnswer from '../images/square-answer.svg'
import triangleAnswer from '../images/triangle-answer.svg'
import correctAnswer from '../images/correct.svg'
import incorrectAnswer from '../images/incorrect.svg'
import pinCorrect from '../images/pin-correct.png'
import pinincorrect from '../images/pin-incorrect.png'

// const questionsData = [
//     { questionId: 0, question: "What is 6 + 3?", answerTriangle: { content: "9", correct: true }, answerCircle: { content: "8", correct: false } },
//     { questionId: 1, question: "What is the capital of France?", answerTriangle: { content: "Paris", correct: true }, answerCircle: { content: "London", correct: false } },
//     { questionId: 2, question: "What is the boiling point of water?", answerTriangle: { content: "90°C", correct: false }, answerCircle: { content: "100°C", correct: true } }
// ];

const Game = ({ gameData, gamePinCode, questionsData, setGameData, setStateManager, gameTimeLimit, setGameCompleted, setAnswerData, firewallLogo, soundEffects }) => {
    const [currentLevel, setCurrentLevel] = useState(0);
    const [countdown, setCountdown] = useState(gameTimeLimit / 1000);
    const [gameState, setGameState] = useState('question'); // States are: "question", "checkAnswer" and "pin" or "validate"
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [userPin, setUserPin] = useState([]);
    const [ userSelection, setUserSelection] = useState(null) // set to "a" or "b" when they press a button. This is for the checkAnswer state and should be null after

    // TODO update data with startTime
    useEffect(() => {
        if (!gameData.startTime) {
            console.log("updating start time");
            const startTime = Date.now();
            updateGameData('startTime', startTime);
        }
    }, []);

    useEffect(()=> {
        if(gameState === 'question') {
            setUserSelection(null);
        }
    }, [gameState])

    // load first answer set on page load
    useEffect(() => {
        if (currentAnswer === null) {
            getNextAnswerSet(currentLevel);
        }

        const handleKeyDown = (event) => {
            if (gameState === 'pin' && event.key >= '0' && event.key <= '9') {
                setUserPin(prevPin => {
                    const newPin = [...prevPin, event.key];
                    return newPin;
                });

            } else if (gameState === 'question' && (event.key === 'a' || event.key === 'b')) {
                // play button pressed
                soundEffects.buttonPress.play();
                checkAnswer(event.key);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentAnswer, currentLevel, gameState]);

    /* check to see if the next 2 digits have been entered by the user
    if so, check to see if pin is now complete and can be validated
    if pin is not ready to be validated, display next question
    */
    useEffect(() => {
        // play pin input sound
        soundEffects.pinInput.play();
        // console.log(((gameData.correctAnswers * 2) + gamePinCode.length) % gamePinCode.length);
        // console.log(userPin.length);
        if (userPin.length === gamePinCode.length && gameState === 'pin') {
            setGameState('validate');
            validatePin();
        } else if (userPin.length === ((gameData.correctAnswers * 2) + gamePinCode.length) % gamePinCode.length) {
            setGameState('question');
        }
    }, [userPin]);


    useEffect(() => {
        if(countdown === 0) {
            const endTime = Date.now();
            updateGameData('endTime', endTime);
            setStateManager(2); // moves from game screen to end game screen
        }

        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
    
        return () => clearInterval(timer);
    }, [countdown])

    const getNextAnswerSet = (questionNumber) => {
        const randomizeAnswers = Math.random() > 0.5;
    
        if (randomizeAnswers) {
            setCurrentAnswer({
                a: { questionId: questionsData[questionNumber].questionId, content: questionsData[questionNumber].answerTriangle.content, correct: questionsData[questionNumber].answerTriangle.isCorrect },
                b: { questionId: questionsData[questionNumber].questionId, content: questionsData[questionNumber].answerCircle.content, correct: questionsData[questionNumber].answerCircle.isCorrect }
            });
        } else {
            setCurrentAnswer({
                a: { questionId: questionsData[questionNumber].questionId, content: questionsData[questionNumber].answerCircle.content, correct: questionsData[questionNumber].answerCircle.isCorrect },
                b: { questionId: questionsData[questionNumber].questionId, content: questionsData[questionNumber].answerTriangle.content, correct: questionsData[questionNumber].answerTriangle.isCorrect }
            });
        }
    };
    

    const validatePin = () => {
        if (userPin.length === 6 && userPin.join('') === gamePinCode.join('')) {
            console.log("Pin is correct!");
            // play sound
            soundEffects.pinCorrect.play();
            updateGameData('correctPinGuess', 1);
            const endTime = Date.now();
            updateGameData('endTime', endTime);
            setTimeout(() => {
                setGameCompleted(true);
                setStateManager(2);
            }, 2000);
        } else if (userPin.length === 6 && userPin.join('') !== gamePinCode.join('')) {
            console.log("Pin is incorrect");
            // play sound
            soundEffects.pinIncorrect.play();
            setGameData(prevData => {
                const currentData = prevData || { startTime: null, endTime: null, correctPinGuess: 0, incorrectPinGuess: 0, correctAnswers: 0, incorrectAnswers: 0 };
                return {
                    ...currentData,
                    incorrectPinGuess: (currentData.incorrectPinGuess || 0) + 1, // Increment incorrectPinGuess
                    correctAnswers: 0, // Reset correctAnswers
                    incorrectAnswers: 0 // Reset incorrectAnswers
                };
            });
            
            setTimeout(() => {
                setGameState('question');
                setUserPin([]);
            }, 2000);
        }
    };

    function checkAnswer(answer) {
        // set current state to 'checkAnswer
        // if ((answer === 'a' && currentAnswer.a.correct) || (answer === 'b' && currentAnswer.b.correct)) {
        //     setUserSelection()
        // }
        setUserSelection(answer);
        setGameState('checkAnswer')

        if(currentAnswer[answer].correct) {
            soundEffects.correct.play();
        } else {
            soundEffects.incorrect.play();
        }


        // record if a question is correct or incorrect
        setAnswerData(prevAnswerData => [
            ...prevAnswerData,
            {
                questionId: currentAnswer[answer].questionId,
                correct: currentAnswer[answer].correct
            }
        ]);


        // set a delay before moving to show the next 2 pin digits (if correct answer) or next question
        setTimeout(() => {
            if ((answer === 'a' && currentAnswer.a.correct) || (answer === 'b' && currentAnswer.b.correct)) {
                console.log("correct!");
                updateGameData('correctAnswers', 1);
                // play pin appears sound
                soundEffects.pinAppears.play();
                setGameState('pin');
            } else {
                console.log("incorrect!");
                updateGameData('incorrectAnswers', 1);
                setGameState('question');
            }

            if (currentLevel < questionsData.length - 1) {
                const nextLevel = currentLevel + 1;
                setCurrentLevel(nextLevel);
                getNextAnswerSet(nextLevel);
            } else {
                setCurrentLevel(0);
                console.log("run out of questions...");
            }
        }, 2000); // 1 second delay to show the user feedback on their question attempt
    }

    // update values of the game data record
    // game data format should be as follows:
    // {startTime: null, endTime: null, incorrect: 0, correct: 0}
    const updateGameData = (key, value) => {
        setGameData(prevData => {
            const currentData = prevData || { startTime: null, endTime: null, correctAnswers: 0, incorrectAnswers: 0 };
            return {
                ...currentData,
                [key]: (key === 'correctAnswers' || key === 'incorrectAnswers' || key === 'correctPinGuess' || key === 'incorrectPinGuess') ? (currentData[key] || 0) + value : value
            };
        });
    };

    function renderAnswers() {
        return(
            <>
                {/* <div key={currentAnswer.a.content} className="flex flex-col w-1/2">
                    { gameState === 'question' ? // if not question, then it must be showing whether the player got the answer correct or incorrect
                        <img src={triangleAnswer} className="h-[100px] mx-auto mb-3" /> 
                            : 
                        <img src={(userSelection === 'a' && currentAnswer.a.correct) ? correctAnswer : triangleAnswer} className={`h-[100px] mx-auto mb-3 ${userSelection !== 'a' ? 'opacity-40' : ''}`} /> }
                    { gameState === 'question' ? 
                        <p>{currentAnswer !== null ? currentAnswer.a.content : "loading..."}</p> 
                            :
                        <p>{(userSelection === 'a' && currentAnswer.a.correct) ? "Correct!" : currentAnswer.a.content }</p> }
                </div> */}
                <div key={currentAnswer.a.content} className="flex flex-col w-1/2">
                    { gameState === 'question' &&
                        <>
                            <img src={triangleAnswer} className="h-[100px] mx-auto mb-3" /> 
                            <p>{currentAnswer !== null ? currentAnswer.a.content : "loading..."}</p>
                        </>
                    }
                    { ((gameState === 'checkAnswer' || gameState === 'pin') && userSelection === 'a') &&
                        <>
                            <motion.img initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}} src={(userSelection === 'a' && currentAnswer.a.correct) ? correctAnswer : incorrectAnswer} className={`h-[100px] mx-auto mb-3 ${userSelection !== 'a' ? 'opacity-40' : ''}`} /> 
                            <motion.p initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}}>{(userSelection === 'a' && currentAnswer.a.correct) ? "Correct!" : currentAnswer.a.content }</motion.p> 
                        </>
                    }
                    { ((gameState === 'checkAnswer' || gameState === 'pin') && userSelection !== 'a') &&
                        <>
                            <img src={triangleAnswer} className={`h-[100px] mx-auto mb-3 opacity-40`} /> 
                            <p>{currentAnswer.a.content }</p> 
                        </>
                    }
                </div> 
                <div key={currentAnswer.b.content} className="flex flex-col w-1/2">
                    { gameState === 'question' &&
                        <>
                            <img src={squareAnswer} className="h-[100px] mx-auto mb-3" /> 
                            <p>{currentAnswer !== null ? currentAnswer.b.content : "loading..."}</p>
                        </>
                    }
                    { ((gameState === 'checkAnswer' || gameState === 'pin') && userSelection === 'b') &&
                        <>
                            <motion.img initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}} src={(userSelection === 'b' && currentAnswer.b.correct) ? correctAnswer : incorrectAnswer} className={`h-[100px] mx-auto mb-3 ${userSelection !== 'b' ? 'opacity-40' : ''}`} /> 
                            <motion.p initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}}>{(userSelection === 'b' && currentAnswer.b.correct) ? "Correct!" : currentAnswer.b.content }</motion.p> 
                        </>
                    }
                    { ((gameState === 'checkAnswer' || gameState === 'pin') && userSelection !== 'b') &&
                        <>
                            <img src={squareAnswer} className={`h-[100px] mx-auto mb-3 opacity-40`} /> 
                            <p>{currentAnswer.b.content }</p> 
                        </>
                    }
                </div> 
            </>
        )
    }

    function RenderGameComponent() {

        switch (gameState) {
            case 'question':
                return (
                    <>
                        {/* THIS COMPONENT KEEPS RE-RENDERING EVERY SECOND and countdown is now gone */}
                        <div className="-mt-[150px] mb-[150px] text-[3rem]"> {String(countdown).padStart(2, '0')}</div>
                        <motion.div key={questionsData[currentLevel].question} initial={{y: 50, opacity: 0}} animate={{y: 0, opacity: 1}} className="w-2/3 mx-auto text-center mb-16">
                            <h1  className="text-[2.5rem]">
                                { questionsData[currentLevel].question }
                            </h1>
                        </motion.div>
                        <div className="flex flex-row text-[2rem] w-3/4 place-content-around text-center space-x-10">
                            { currentAnswer && renderAnswers() }
                        </div>
                    </>
                );
            case 'checkAnswer':
                return (
                    <>
                        <div className="-mt-[150px] mb-[150px] text-[3rem]"> {String(countdown).padStart(2, '0')}</div>
                        <div className="w-2/3 mx-auto text-center mb-16">
                            <h1  className="text-[2.5rem]">
                                { questionsData[currentLevel].question }
                            </h1>
                        </div>
                        <div className="flex flex-row text-[2rem] w-3/4 place-content-around text-center space-x-10">
                            { currentAnswer && renderAnswers() }
                        </div>
                    </>
                );
            case 'pin':
                return (
                    <>
                        <motion.div initial={{y: -50, opacity: 0}} animate={{y: 0, opacity: 1}} key={`currentLevel_${gameData.correctAnswers}`} className="w-2/3 mx-auto text-center mb-16">
                                <h3 className="text-[2rem] mb-2 drop-shadow-[0_0px_6px_rgba(29,224,254,1)]">Your Code:</h3>
                                <div className="flex flex-row mx-auto justify-center space-x-6">
                                    <motion.h1 key="pin_0" initial={{ y: 50, opacity: 0}} animate={{y: 0, opacity: 1, transition: {delay: 0.2}}} className="text-[6rem] drop-shadow-[0_0px_6px_rgba(29,224,254,1)]">{gamePinCode[(((gameData.correctAnswers - 1) * 2) + gamePinCode.length) % gamePinCode.length]}</motion.h1>
                                    <motion.h1 key="pin_1" initial={{ y: 50, opacity: 0}} animate={{y: 0, opacity: 1, transition: {delay: 0.5}}}  className="text-[6rem] drop-shadow-[0_0px_6px_rgba(29,224,254,1)]">{gamePinCode[(((gameData.correctAnswers - 1) * 2) + 1 + gamePinCode.length) % gamePinCode.length]}</motion.h1>
                                </div>
                        </motion.div>
                    </>
                );
            case 'validate':
                return (
                    <div>
                        <div className="mx-auto text-center overflow-hidden">   
                            <motion.img initial={{x: -350, opacity: 0}} animate={{x: 0, opacity: 1}} 
                                src={userPin.join('') === gamePinCode.join('') ? pinCorrect : pinincorrect}
                                className="h-[110px] mx-auto mb-12" />
                            { userPin.join('') !== gamePinCode.join('') && 
                            <motion.p initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 0.5}}}
                                className="text-[2rem] drop-shadow-[0_0px_6px_rgba(29,224,254,1)] w-2/3 mx-auto">
                                <>Pin incorrect,<br />please try again</> 
                            </motion.p>}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    const renderCodeDigits = () => {
        const pinPad = [];
        // console.log(userPin.length)
        for(let i = 0; i < 6; i++) {
            pinPad.push(
                <div key={`pinPad_${i}`} className="relative h-[110px]">
                    <span className="drop-shadow-[0_0px_6px_rgba(29,224,254,1)]">{userPin[i] !== undefined ? userPin[i] : ""}</span>
                    {(i === userPin.length && gameState === 'pin') && <span className="absolute top-7 left-2 h-[55%] w-3 bg-white opacity-70 animate-pulse"></span>}
                    <span className={`bg-black w-[100%] h-1.5 absolute left-0 bottom-1 border border-white`}></span>
                </div>
            )
        }

        return pinPad;
    }

    return (
        <div className="min-h-screen grid grid-cols-6 mx-20 gap-14">
            <img src={firewallLogo} className="absolute top-20 right-32 w-[15%]" />
            <div key={currentLevel} className="col-span-4 flex flex-col items-center my-auto font-dmmono font-semibold">
                { RenderGameComponent() }
            </div>
            <motion.div initial={{x: "100%"}} animate={{x: 0}} className="col-span-2 flex flex-col items-center my-auto bg-center bg-contain bg-no-repeat h-[40%]" style={{ backgroundImage: `url(${pinContainerBackground})`}}>
                <div className="flex flex-col items-center my-auto space-y-8 w-[70%]">
                    <div className="grid grid-cols-6 gap-4 text-center mx-[10%] text-[5rem] font-semibold font-dmmono w-full">
                        { renderCodeDigits() }
                    </div>
                    <div className="">
                        <p className="text-[1.1rem] font-dmmono font-semibold text-[#0B1426]">
                            Use the PIN pad to enter the code.
                        </p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Game;