import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedPages from './pages/animatedPages';
import { GlobalProvider } from './pages/GlobalContext';

import { io } from "socket.io-client";


function App() {
  return (
    <GlobalProvider> 
    <Router>
      {/* Global Context for retaining wristband/userID and setting sessionId */}
        <AnimatedPages />
    </Router>
    </GlobalProvider>
  );
}

export default App;
