import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import FirewallGame from "./components/FirewallGame";
import { GlobalContext } from "../../GlobalContext";
import { useEndGameSession } from "../../../pages/api_functions/useEndGameSession";
import { useSetGameStatus } from "../../api_functions/SetGameStatus";
import { useGetGameData } from "../../api_functions/useGetGameData";
import { useAbandonGame } from "../../api_functions/useAbandonGame";
import { useCheckSessionStatus } from "../../api_functions/useCheckSessionStatus";
import debounce from "lodash.debounce";
import { Howl } from "howler";
import { usePulse } from "../../api_functions/usePulse";

import { motion } from "framer-motion";

// images
import firewallBackground from "./images/firewall_background.png";
import firewallLogo from "./images/firewall_logo.svg";
import firewallButton from "./images/firewall_button.svg";

import gameMusic from "./audio/firewall-music.mp3";
import correctSFX from "./audio/correct.mp3";
import incorrectSFX from "./audio/incorrect.mp3";
import menuLoadSFX from "./audio/firewall-menu.mp3";
import buttonPressSFX from "./audio/buttonPressed.mp3";
import pinAppearsSFX from "./audio/pinappears.mp3";
import pinInputSFX from "./audio/pininput.mp3";
import pinCorrectSFX from "./audio/pinCorrect.mp3";
import pinIncorrectSFX from "./audio/pinIncorrect.mp3";

const soundEffects = {
  pinAppears: new Howl({ src: [pinAppearsSFX] }),
  pinInput: new Howl({ src: [pinInputSFX] }),
  buttonPress: new Howl({ src: [buttonPressSFX] }),
  correct: new Howl({ src: [correctSFX] }),
  incorrect: new Howl({ src: [incorrectSFX] }),
  menuLoad: new Howl({ src: [menuLoadSFX] }),
  music: new Howl({ src: [gameMusic], loop: true, volume: 0.1 }),
  pinCorrect: new Howl({ src: [pinCorrectSFX] }),
  pinIncorrect: new Howl({ src: [pinIncorrectSFX] }),
};

export default function FirewallGameContainer() {
  const { gameSessionData, showHud, setGameSessionData, socket } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [stateManager, setStateManager] = useState(0); // 0: menu 1: game 2: end
  const [gameData, setGameData] = useState({});
  const [answerData, setAnswerData] = useState([]); // used to capture questions answered eg [{questionId: 0, correct: false}, ...]
  const [gameCompleted, setGameCompleted] = useState(false);
  const gameTimeLimit = 90000; // 1 minute 30 seconds
  const stateRef = useRef(stateManager);
  const intervalRef = useRef(null);

  if (stateRef.current !== stateManager) stateRef.current = stateManager;

  // const tempGamePinCode = [6,3,4,4,1,5];

  const { setSessionStatus, error: setSessionStatusError } = useSetGameStatus();
  const { endGameSession, error: endGameSessionError } = useEndGameSession();
  const { error: dataError, getGameData, gameStateData } = useGetGameData(2); // firewall gameId
  const { abandonGameSession } = useAbandonGame(); // if timeout on menu screen
  // const { checkSessionStatus, sessionStatus } = useCheckSessionStatus(); // check to see if session has been abandoned via dashboard/db update

  usePulse("firewall", gameSessionData?.gamesessionid, stateManager);

  // Play and stop music based on stateManager
  // CH note: use a REF instead of state for the below
  useEffect(() => {
    if (stateManager === 1) {
      soundEffects.music.play(); // Play music when game starts
    } else if (stateManager === 2) {
      soundEffects.music.fade(0.1, 0, 2000); // Fade out music over 2 seconds
    } else if (stateManager === 0) {
      soundEffects.menuLoad.play();
    }
  }, [stateManager]);

  // redirect back to wristband search screen if no session ID is found in gameSessionData
  useEffect(() => {
    if (!gameSessionData.gamesessionid && !gameSessionData.testing) {
      navigate(`/firewall`);
    }
  }, [navigate]);

  useEffect(() => {
    if (socket) {
      socket.on("game-session", (data) => {
        switch (data.type) {
          case "abandoned":
            if (
              data.game === "firewall" &&
              data.gameSessionId === gameSessionData.gamesessionid
            ) {
              setGameSessionData((prevData) => ({
                ...prevData,
                alias: null,
                currentrfidtagid: null,
                gamesessionid: null,
              }));

              navigate(`/firewall`);
            }
            break;
          case "playing":
          case "completed":
            if (stateRef.current === 0) {
              window.location.href = `/firewall`;
            }
            break;
          default:
            break;
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("game-session"); // Clean up the event listener
      }
    };
  }, [socket]);

  const handleKeyDown = useCallback(
    debounce((event) => {
      if (event.key === "a" || event.key === "b") {
        if (stateManager === 0) {
          setStateManager(1);
        }
      }
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (stateManager !== 0) {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (stateManager === 1 && !gameStateData?.pin) {
      // Start interval to refetch PIN every 5 seconds
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          getGameData();
        }, 5000);
      }
    } else if (intervalRef.current) {
      // Clear the interval if PIN is available or stateManager changes
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // Clean up on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [stateManager, gameStateData?.pin]);

  // For submitting the game data once game has ended
  useEffect(() => {
    if (stateManager === 2) {
      console.log(answerData);
      // Params: Game Data ID, Game Status No, Game Data
      endGameSession(2, 6, {
        RFIDTagId: gameSessionData.currentrfidtagid
          ? gameSessionData.currentrfidtagid
          : 0,
        PuzzleData: {
          QuizAnswers: {
            IncorrectCount: gameData.incorrectAnswers
              ? gameData.incorrectAnswers
              : 0, // NOTE: revert to 0 each time pin is incorrect
            SuccessCount: gameData.correctAnswers ? gameData.correctAnswers : 0, // NOTE: revert to 0 each time pin is incorrect
            PinCorrect: gameData.correctPinGuess === 1,
            IncorrectPinAttempts: gameData.incorrectPinGuess,
            StartTime: gameData.startTime,
            EndTime: gameData.endTime,
            CompletionSeconds: Math.floor(
              (gameData.endTime - gameData.startTime) / 1000
            ),
          },
          AnswerList: answerData,
        },
      });
    } else if (stateManager === 1 && gameStateData?.pin) {
      // user has started the game, PATCH to update game status to "playing" (3)
      console.log("patch to mark game session as playing");
      // Params: game session ID, game staus ID type (2 = ready, 3 = playing, 4 = complete), game status event ID (5, 6 or 7 depending on game)
      setSessionStatus(gameSessionData.gamesessionid, 3, 6);
    }
  }, [stateManager, endGameSession]);

  return (
    <div className={`w-full h-screen overflow-hidden relative`}>
      <motion.div
        initial={{ scale: 1.2, opacity: 0.0 }}
        animate={
          stateManager === 1
            ? { scale: 1, opacity: 1 }
            : { scale: 1.1, opacity: 0.6 }
        }
        className={`absolute inset-0 bg-cover bg-center before:absolute before:inset-0 before:bg-black -z-10 transition-opacity duration-1000 ${
          stateManager === 1 ? "before:opacity-0" : "before:opacity-0"
        }`}
        style={{ backgroundImage: `url(${firewallBackground})` }}></motion.div>
      {stateManager === 0 && (
        <Menu
          setStateManager={setStateManager}
          gameSessionData={gameSessionData}
          gameStateData={gameStateData}
          abandonGameSession={abandonGameSession}
        />
      )}
      {stateManager === 1 && !gameStateData?.pin && (
        <div className="w-full h-full flex flex-col items-center justify-center animate-pulse text-center">
          Loading... <br /> Waiting for PIN from server.
          <br />
          <br /> {dataError}
        </div>
      )}
      {stateManager === 1 && gameStateData?.pin && (
        <FirewallGame
          gameData={gameData}
          gamePinCode={gameStateData.pin}
          questionsData={gameStateData.questionsData}
          setGameData={setGameData}
          setStateManager={setStateManager}
          gameTimeLimit={gameTimeLimit}
          setGameCompleted={setGameCompleted}
          setAnswerData={setAnswerData}
          answerData={answerData}
          firewallLogo={firewallLogo}
          soundEffects={soundEffects}
        />
      )}
      {stateManager === 2 && <End showHud={showHud} />}
      {endGameSessionError && (
        <div>Error posting game event: {endGameSessionError}</div>
      )}
    </div>
  );
}

export function Menu({
  setStateManager,
  gameSessionData,
  gameStateData,
  abandonGameSession,
}) {
  const isButtonDisabled = !gameStateData?.questionsData || !gameStateData?.pin;

  // auto time out after 5 mins
  const [countdown, setCountdown] = useState(300);

  useEffect(() => {
    if (gameStateData?.timeOut) {
      setCountdown(gameStateData.timeOut);
    }
  }, [gameStateData]);

  useEffect(() => {
    if (countdown === 0) {
      abandonGameSession("firewall");
    }

    // CH note: move into a separate useEffect
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, abandonGameSession]);

  return (
    <>
      <div className="container mx-auto min-h-screen flex flex-col items-center justify-center space-y-12 text-center">
        <motion.img
          initial={{ y: 100, scale: 0.8, opacity: 0.4 }}
          animate={{ y: 0, scale: 1, opacity: 1, animation: { duration: 0.8 } }}
          src={firewallLogo}
          className="-mt-14 mb-14"
        />
        <div className="w-3/4 space-y-8 text-[1.6rem] font-bold uppercase py-14 font-dmmono drop-shadow-[0_0px_6px_rgba(29,224,254,1)]">
          {gameSessionData.alias && (
            <motion.p
              className="text-[2.2rem]"
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}>
              Welcome to the second challenge,
              <span className="text-[#7fd571]"> {gameSessionData.alias}</span>
            </motion.p>
          )}
          <motion.p
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}>
            Test your speed and knowledge by answering questions.
          </motion.p>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}>
            Each question will give you 2 digits of the code that must entered
            into the keypad.
          </motion.p>
          <motion.p
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}>
            Enter all 6 digits correctly to break the firewall!
          </motion.p>
        </div>
        <div className=" flex flex-col items-center relative leading-9">
          <motion.img
            key={"button-frame-right"}
            initial={{ x: 100, y: -30, opacity: 0.2 }}
            animate={{
              x: 3,
              y: -3,
              opacity: 0.7,
              transition: { type: "tween", duration: 0.2 },
            }}
            whileTap={{ x: 0, y: 0, opacity: 0.9 }}
            className={`absolute top-0 left-0 w-full ${
              isButtonDisabled ? "animate-pulse" : ""
            }`}
            src={firewallButton}
          />
          <motion.img
            key={"button-frame-left"}
            initial={{ x: -100, y: 30, opacity: 0.2 }}
            animate={{
              x: -3,
              y: 3,
              opacity: 0.7,
              transition: { type: "tween", duration: 0.2 },
            }}
            whileTap={{ x: 0, y: 0, opacity: 0.9 }}
            className={`absolute top-0 left-0 w-full ${
              isButtonDisabled ? "animate-pulse" : ""
            }`}
            src={firewallButton}
          />
          <motion.button
            disabled={isButtonDisabled}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}
            onClick={() => setStateManager(1)}
            className={`w-[355px] h-[140px] uppercase text-[2rem] mt-1 font-dmmono font-semibold ${
              !isButtonDisabled
                ? "drop-shadow-[0_0px_6px_rgba(29,224,254,1)]"
                : "drop-shadow-[0_0px_1px_rgba(29,224,254,1)]"
            }`}>
            Press any button to Begin
          </motion.button>
        </div>
      </div>
    </>
  );
}

// return to wristband search screen after 5 seconds
export function End({ showHud }) {
  const [countdown, setCountdown] = useState(5); // seconds

  useEffect(() => {
    if (countdown === 0 && !showHud) {
      window.location.href = `/firewall`;
    }

    // CH note: do the same here, move the setInterval out
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 2000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <>
      <div className="container mx-auto min-h-screen flex flex-col items-center justify-center space-y-6">
        <h1 className="text-[4rem] drop-shadow-[0_0px_6px_rgba(29,224,254,1)] font-dmmono -mb-6">
          Challenge Completed
        </h1>
        <h3 className="text-[2rem] drop-shadow-[0_0px_6px_rgba(29,224,254,1)] font-dmmono pb-6">
          Please continue to the next challenge
        </h3>
        <p>Redirecting in {countdown} seconds...</p>
      </div>
    </>
  );
}
