// import { useContext } from 'react';
// import { GlobalContext } from '../GlobalContext';
import { useCallback } from "react";
// import useLocalStorage from "./useLocalStorage";

const useAuth = () => {
  // const { gameSessionData, setGameSessionData } = useContext(GlobalContext);
  //   const [setValue] = useLocalStorage("token", null);

  const login = useCallback(async () => {
    const endpoint = process.env.REACT_APP_API_ENDPOINT;
    const response = await fetch(`${endpoint}/auth/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "playmaxx-admin@2dm.net.au",
        password: process.env.REACT_APP_AUTH_PASSWORD,
      }),
    });
    console.log("response", response);

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    localStorage.setItem("token", data.token);

    return data.token;
  }, []);

  return { login };
};

export default useAuth;
