import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { usePlayerData } from "./api_functions/RequestPlayerData";
import { GlobalContext } from "./GlobalContext";
import { motion } from "framer-motion";
import { usePulse } from "./api_functions/usePulse";

import playMaxxLogo from "../img/playmaxx-logo.png";
import wristbandGlow from "../img/wristband-glow.svg";
import wristbandImg from "../img/wristband.png";

export default function CheckIn() {
  const { game } = useParams();
  const navigate = useNavigate();

  const { gameSessionData, setGameSessionData, socket } = useContext(GlobalContext);
  const { fetchPlayerData } = usePlayerData(game);
  usePulse(game, 0, 4); // game name, player's session ID (0 at this point, no player), 4 = searching

  const [message] = useState("Searching for players...");

  // check on load if there is already a player waiting
  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  const sphereAnimation = {
    initial: {
      scale: 3,
      opacity: 0.2,
    },
    animate: {
      scale: [1, 1.1],
      opacity: [1, 0.6],
      transition: {
        duration: 1.6,
        repeatType: "mirror",
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  useEffect(() => {
    // Example of handling a socket event
    if (socket) {
      socket.on("game-session", (data) => {
        switch (data.type) {
          case "ready":
            if (
              data.game === game ||
              (data.game === "crackthesafe" && game === "crack-the-safe")
            ) {
              // route is crack-the-safe however socket will show 'crackthesafe'
              setGameSessionData((prevData) => ({
                ...prevData,
                alias: data.playersReady[0].alias,
                currentrfidtagid: data.playersReady[0].currentrfidtagid,
                gamesessionid: data.playersReady[0].gamesessionid,
              }));
            }
            break;
          default:
            break;
        }
      });
    }
    return () => {
      if (socket) {
        socket.off("game-session"); // Clean up the event listener
      }
    };
  }, [socket, game, setGameSessionData]);

  // if connectivity is lost, manually check for ready games if offline
  useEffect(() => {
    const handleOnline = () => {
      // manually check to see if there is a player ready
      console.log("Back online");
      fetchPlayerData();
    };

    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, [fetchPlayerData]);

  useEffect(() => {
    if (gameSessionData && gameSessionData?.gamesessionid) {
      console.log("got session data", gameSessionData);
      navigate(`/${game}/game`);
    }
  }, [gameSessionData, game, navigate]);

  return (
    <div className="container mx-auto h-screen text-center flex flex-col place-content-between items-center">
      <div className="h-1/5 lg:py-[5%] xl:py-[2.5%]">
        <img src={playMaxxLogo} className="lg:w-4/5 xl:w-2/5 mx-auto" />
      </div>
      <div className="h-3/5 flex flex-col items-center justify-center">
        <motion.img
          alt="Wristband image"
          initial={{ scale: 0.7, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
            transition: { duration: 0.2, delay: 0.3, ease: "easeOut" },
          }}
          src={wristbandImg}
          className="mx-auto lg:h-[50%] xl:h-[68%]"
        />
      </div>
      <div className="h-1/5">
        <h3 className="animate-pulse lg:text-[2rem] xl:text-[1.2rem] xl:mt-[40%] uppercase">
          {message}
        </h3>
        {/* { playerDataError && <p className=''>Error: {playerDataError}</p> } */}
      </div>

      {/* Animated white circle */}
      <div className="bg-black w-screen h-screen absolute left-0 top-0 -z-10 flex items-center justify-center">
        <motion.img
          key="sphere"
          variants={sphereAnimation}
          initial="initial"
          animate="animate"
          src={wristbandGlow}
          className="mx-auto xl:h-[68%]"
        />
      </div>
    </div>
  );
}

// The below is for testing, so that various states can easily be tested
export function UpdateRfidState({ setState }) {
  return (
    <div className="flex flex-row absolute left-0 bottom-10 w-screen space-x-4 text-sm font-semibold px-4">
      <button
        className="w-1/4 mx-auto rounded-lg bg-emerald-600 hover:bg-emerald-400 active:bg-orange-400 py-2"
        onClick={() => setState("searching")}>
        Searching
      </button>
      <button
        className="w-1/4 mx-auto rounded-lg bg-emerald-600 hover:bg-emerald-400 active:bg-orange-400 py-2"
        onClick={() => setState("found")}>
        Found
      </button>
      <button
        className="w-1/4 mx-auto rounded-lg bg-emerald-600 hover:bg-emerald-400 active:bg-orange-400 py-2"
        onClick={() => setState("creatingSession")}>
        Creating Session
      </button>
      <button
        className="w-1/4 mx-auto rounded-lg bg-emerald-600 hover:bg-emerald-400 active:bg-orange-400 py-2"
        onClick={() => setState("sessionCreated")}>
        Session Created
      </button>
    </div>
  );
}
