import { useState, useCallback, useContext, useRef, useEffect } from "react";
import { GlobalContext } from "../GlobalContext";
import useAuth from "./useAuth";
// import useLocalStorage from "./useLocalStorage";

export const useEndGameSession = () => {
  const [error, setError] = useState(null);
  const { gameSessionData } = useContext(GlobalContext);
  const { login } = useAuth();
  // const [storedValue] = useLocalStorage("token", null);
  const token = useRef(localStorage.getItem("token"));
  useEffect(() => {
    const updateToken = () => {
      token.current = localStorage.getItem("token");
    };
    window.addEventListener("storage", updateToken);
    return () => window.removeEventListener("storage", updateToken);
  }, []);

  const endGameSession = useCallback(
    async (gameId, gameEventTypeID, gameData) => {
      if (token.current === null) {
        await login();
        return endGameSession(gameId, gameEventTypeID, gameData);
      } else {
        try {
          // Post game data
          const postGameDataResponse = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
              body: JSON.stringify({
                gameSessionId: gameSessionData.gamesessionid
                  ? gameSessionData.gamesessionid
                  : 0,
                gameSessionEventTypeId: gameId,
                gameData: JSON.stringify(gameData),
              }),
            }
          );

          if (!postGameDataResponse.ok) {
            if (postGameDataResponse.status === 401) {
              await login();
              return endGameSession(gameId, gameEventTypeID, gameData);
            }
            throw new Error(
              `Error: ${postGameDataResponse.status} ${postGameDataResponse.statusText}`
            );
          } else {
            // console.log("Game session event successfully posted game data")
          }

          // Mark session as complete
          const postStatusResponse = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
              body: JSON.stringify({
                gameSessionId: gameSessionData.gamesessionid
                  ? gameSessionData.gamesessionid
                  : 0,
                gameSessionEventTypeId: gameEventTypeID,
                gameData: JSON.stringify({
                  RFIDTagId: gameSessionData.currentrfidtagid,
                  Status: "Complete",
                }),
              }),
            }
          );

          if (!postStatusResponse.ok) {
            throw new Error(
              `Error: ${postStatusResponse.status} ${postStatusResponse.statusText}`
            );
          } else {
            // console.log("Game session event successfully updated status to complete")
          }

          // Call to set status as complete
          const patchStatusUpdate = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSession/${gameSessionData.gamesessionid}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
              body: JSON.stringify({
                gameSessionStatusId: 4, // 4 = complete
              }),
            }
          );

          if (!patchStatusUpdate.ok) {
            throw new Error(
              `Error: ${patchStatusUpdate.status} ${patchStatusUpdate.statusText}`
            );
          } else {
            // console.log("successfully patched game session id to be complete")
          }

          // Call do game engine
          const doGameEngineResponse = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSession/DoGameEngine/${gameSessionData.gamesessionid}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
            }
          );

          if (!doGameEngineResponse.ok) {
            throw new Error(
              `Error: ${doGameEngineResponse.status} ${doGameEngineResponse.statusText}`
            );
          } else {
            console.log("successfully run DoGameEngine");
          }

          console.log("All API calls were successful.");
        } catch (error) {
          setError(error.message);
          console.error("Error during API calls:", error);
        }
      }
    },
    [gameSessionData.currentrfidtagid, gameSessionData.gamesessionid, login]
  );

  return { endGameSession, error };
};
