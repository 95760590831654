import React, { useState, useEffect, useCallback} from "react";
import constrain from "./constrain";
import { debounce } from "lodash";
import { motion } from "framer-motion";
import { Howl } from 'howler';

import crackTheSafeLogo from '../images/crack-the-safe-logo.svg'
import pinInput from '../images/pin-input.png'
import pinInputCorrect from '../images/pin-input-correct.png'
import pinInputIncorrect from '../images/pin-input-incorrect.png'

const pinImages = {
    initial: pinInput,
    correct: pinInputCorrect,
    incorrect: pinInputIncorrect
}

const Game = ({ gameData, gamePinCode, setGameData, setStateManager, gameTimeLimit, setGameCompleted, openSafe, soundEffects }) => {
    const [ currentLevel, setCurrentLevel ] = useState(0);
    const [ countdown, setCountdown ] = useState(gameTimeLimit / 1000);
    const [ gameState, setGameState ] = useState('pin'); // States are: "pin" or "validate"
    const [ userPin, setUserPin] = useState([0,0,0])
    const [ currentIndex, setCurrentIndex] = useState(0)
    const [ animDir, setAnimDir] = useState(1) // either 1 or -1 depending on key pressed so that digits animate on the correct direction
    const [ pinValidationState, setPinValidationState] = useState('initial') // used to select the correct image from the pinImages object (above)

    useEffect(() => {
        if(!gameData.startTime) {
            console.log("updating start time")
            const startTime = Date.now();
            updateGameData('startTime', startTime);
        }
    }, [])

    /* Manage keyboard input:
     Left Arrow = Decrease Value
     Right Arrow = Increase Value
     Enter = Confirm Value and move to input next digit or to validate attempt
    */
     const handleKeyDown = useCallback(debounce((event) => {
        if (gameState === 'pin') {
            soundEffects.changeDigit.play();
            if (event.key === 'ArrowLeft') {
                setAnimDir(-1);
                setUserPin(prevPin => {
                    const newPin = [...prevPin];
                    newPin[currentIndex] = constrain(newPin[currentIndex] - 1, 0, 9);
                    console.log(newPin);
                    return newPin;
                });
            } else if (event.key === 'ArrowRight') {
                setAnimDir(1);
                setUserPin(prevPin => {
                    const newPin = [...prevPin];
                    newPin[currentIndex] = constrain(newPin[currentIndex] + 1, 0, 9);
                    return newPin;
                });
            } else if (event.key === 'Enter') {
                setCurrentIndex(prevIndex => {
                    const nextIndex = prevIndex + 1;
                    if (nextIndex >= userPin.length) {
                        setUserPin(prevPin => {
                            let newPin;
                            if (prevPin.length === gamePinCode.length) {
                                newPin = [...prevPin]; // Don't add another element if code length is same as the user code
                            } else {
                                newPin = [...prevPin, 0]; // Initialise the next index with 0
                            }
                            return newPin;
                        });
                    }
                    return nextIndex;
                });
            }
        }
    }, 50), [gameState, currentIndex, userPin.length, gamePinCode.length]); // might need to make this loger for the physical dial

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    /* check to see if the next 2 digits have been entered by the user
    if so, check to see if pin is now complete and can be validated
    if pin is not ready to be validated, display next question
    */
    useEffect(() => {
        // console.log(userPin)
        if(currentIndex === gamePinCode.length && gameState === 'pin') {
            setGameState('validate');
            validatePin();
        } 
    }, [userPin])

    // automatically end the game if time is up
    useEffect(() => {
        if(countdown === 0) {
            const endTime = Date.now();
            updateGameData('endTime', endTime);
            setStateManager(2); // moves from game screen to end game screen
        }

        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
    
        return () => clearInterval(timer);
    }, [countdown])

    // update values of the game data record
    // game data format should be as follows:
    // {startTime: null, endTime: null, incorrect: 0, correct: 0}
    const updateGameData = (key, value) => {
        setGameData(prevData => {
            const currentData = prevData || { startTime: null, endTime: null, correctAnswers: 0, incorrectAnswers: 0 };
            return {
                ...currentData,
                [key]: (key === 'correctPinGuess' || key === 'incorrectPinGuess') ? (currentData[key] || 0) + value : value
            };
        });
    };

    const validatePin = () => {
        console.log(userPin.join(''))
        console.log(gamePinCode.join(''))
        if(userPin.join('') === gamePinCode.join('')) {
            // play safe opening animation
            openSafe();
            // play safe opening audio
            soundEffects.openSafeSound.play();
            // soundEffects.pinCorrect.play();
            setPinValidationState('correct');
            updateGameData('correctPinGuess', 1);
            const endTime = Date.now();
            updateGameData('endTime', endTime);
            setTimeout(() => {
                setGameCompleted(true);
                setStateManager(2);
            }, 12000); // let video play (12 seconds)
        } else if(userPin.join('') !== gamePinCode.join('')) {
            console.log("Pin is incorrect")
            soundEffects.pinIncorrect.play();
            updateGameData('incorrectPinGuess', 1);
            setPinValidationState('incorrect');
            setTimeout(() => {
                setPinValidationState('initial');
                setGameState('pin');
                setUserPin([0,0,0]);
                setCurrentIndex(0);
            }, 2000);
        }
    }


    function RenderGameComponent() {
        switch(gameState) {
            case 'pin':
                return(
                /* Display next 2 digits of pin
                   Displaying the next 2 digtis in the sequence by looking at how many correct answers have been made and subtracting 1 (to get corret index for pinCodeArray). gamePinCode[(gameData.correctAnswers - 1) * 2]
                   gamePinCode.length) % gamePinCode.length is used to loop through the pin if they submit an incorrect response
                */
                <div>
                    {/* <h1>VAULT IMAGE</h1> */}
                </div>)
            case 'validate':
                return(
                <div>
                    {/* <h1>Validating...</h1>
                    { userPin.join('') === gamePinCode.join('') ?
                        <h2>Correct!</h2>
                    :
                        <h2>Incorrect</h2>
                    } */}
                </div>)
        }
    }

    // pinpad container animation at end of game
    const pinPadContainer = { 
        initial: { opacity: 1, x: 0, y: 0 },
        animateOut: {opacity: 0, x: 100, y: 0, transition: {duration: 0.8, delay: 0.8}}
    }

    return (
        <div className="min-h-screen z-10 relative flex flex-row">
            {/* LEFT SIDE - VAULT DOOR */}
            <div key={currentLevel} className="w-[1344px] flex flex-col items-center my-auto" >
                <RenderGameComponent />
            </div>

            {/* RIGHT SIDE - CLUES AND COMBO */}
            <div className="w-[576px] flex flex-col h-screen justify-between items-center px-20 py-28 ">
                    <motion.img key="safe_logo" variants={pinPadContainer} initial="initial" animate={pinValidationState === 'correct' ? "animateOut" : "initial" } src={crackTheSafeLogo} className="h-[40px]" />
                    <motion.div variants={pinPadContainer} initial="initial" animate={pinValidationState === 'correct' ? "animateOut" : "initial" } className="text-[1.5rem] font-bold w-[90%] leading-8 drop-shadow-md" >
                        <p className="text-center px-12 mb-8">Use the clues below to Uncover the 3 digit pin:</p>
                        <div className="grid grid-cols-4 gap-y-6">
                            <p className="col-span-1 text-[2rem]">247</p>
                            <p className="col-span-3">One number is correct <br/>and in the right place</p>
                            <p className="col-span-1 text-[2rem]">267</p>
                            <p className="col-span-3">Nothing is correct</p>
                            <p className="col-span-1 text-[2rem]">163</p>
                            <p className="col-span-3">Two numbers are correct and in the wrong place</p>
                        </div>
                    </motion.div>
                    <motion.div key="pinPadContainer" variants={pinPadContainer} initial="initial" animate={pinValidationState === 'correct' ? "animateOut" : "initial" } className="flex flex-row space-x-4 mb-8">
                        <div key="pinpad_1"
                            className="text-[5rem] w-[150px] h-[150px] flex flex-col items-center justify-center bg-center bg-cover font-digital font-semibold"
                            style={{ backgroundImage: `url(${pinImages[pinValidationState]})` }}>
                                <div className={`${(currentIndex !== 0 && pinValidationState === 'initial') && "opacity-40"}`}>
                                    <motion.p key="pin_1" initial={{x: 30 * animDir}} animate={{x: 0}} exit={{x: 30 * -animDir}} className={`${pinValidationState === 'initial' && "text-[#1DE0FE]"}  ${pinValidationState === 'correct' && "text-[#31D59A]"} ${pinValidationState === 'incorrect' && "text-[#B91C66]"}`}>{ userPin[0] !== undefined ? userPin[0] : " " }</motion.p>
                                </div>
                        </div>
                        <div key="pinpad_2"
                            className="text-[5rem] w-[150px] h-[150px] flex flex-col items-center justify-center bg-center bg-cover font-digital font-semibold"
                            style={{ backgroundImage: `url(${pinImages[pinValidationState]})` }}>
                                <div className={`${(currentIndex !== 1 && pinValidationState === 'initial') && "opacity-40"}`}>
                                    <motion.p key="pin_2" initial={{x: 30 * animDir}} animate={{x: 0}} exit={{x: 30 * -animDir}} className={`${pinValidationState === 'initial' && "text-[#1DE0FE]"}  ${pinValidationState === 'correct' && "text-[#31D59A]"} ${pinValidationState === 'incorrect' && "text-[#B91C66]"}`}>{ userPin[1] !== undefined ? userPin[1] : " " }</motion.p>
                                </div>
                        </div>
                        <div key="pinpad_3"
                            className="text-[5rem] w-[150px] h-[150px] flex flex-col items-center justify-center bg-center bg-cover font-digital font-semibold"
                            style={{ backgroundImage: `url(${pinImages[pinValidationState]})` }}>
                                <div className={`${(currentIndex !== 2 && pinValidationState === 'initial') && "opacity-40"}`}>
                                    <motion.p key="pin_3" initial={{x: 30 * animDir}} animate={{x: 0}} exit={{x: 30 * -animDir}} className={`${pinValidationState === 'initial' && "text-[#1DE0FE]"}  ${pinValidationState === 'correct' && "text-[#31D59A]"} ${pinValidationState === 'incorrect' && "text-[#B91C66]"}`}>{ userPin[2] !== undefined ? userPin[2] : " " }</motion.p>
                                </div>
                        </div>
                    </motion.div>
                    <motion.div key="response" variants={pinPadContainer} initial="initial" animate={pinValidationState === 'correct' ? "animateOut" : "initial" } className="text-center font-bold">
                        { pinValidationState === 'initial' && <p className="text-[1.1rem]">Twist the dial to change pin number.<br />Press the dial in to confirm each digit.</p> }
                        { pinValidationState === 'correct' && <motion.p initial={{x: 300, opacity: 0.3}} animate={{x: 0, opacity: 1}} key="response_correct" className="text-[2.2rem] font-digital text-[#31D59A] font-semibold ">CORRECT</motion.p> }
                        { pinValidationState === 'incorrect' && <motion.p initial={{x: 300, opacity: 0.3}} animate={{x: 0, opacity: 1}} key="response_incorrect" className="text-[2.2rem] font-digital text-[#B91C66] font-semibold">INCORRECT</motion.p> }
                        <div className={` text-white font-digital font-semibold text-[4rem] mt-4`}>{String(countdown).padStart(2, '0')}</div>
                    </motion.div>
            </div>
        </div>
    );
};

export default Game;

