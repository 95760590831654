import { useState, useCallback, useContext, useRef, useEffect } from "react";
import { GlobalContext } from "../GlobalContext";
import useAuth from "./useAuth";
// import useLocalStorage from "./useLocalStorage";

export const useCheckSessionStatus = () => {
  // const [error, setError] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(null);
  const { gameSessionData } = useContext(GlobalContext);
  const { login } = useAuth();
  // const [storedValue] = useLocalStorage("token", null);
  const token = useRef(localStorage.getItem("token"));
  useEffect(() => {
    const updateToken = () => {
      token.current = localStorage.getItem("token");
    };
    window.addEventListener("storage", updateToken);
    return () => window.removeEventListener("storage", updateToken);
  }, []);

  const checkSessionStatus = useCallback(async () => {
    if (token.current === null) {
      await login();
      return checkSessionStatus();
    } else {
      try {
        const endpoint = process.env.REACT_APP_API_ENDPOINT;

        const response = await fetch(
          `${endpoint}/gameSession/${gameSessionData.gamesessionid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.current}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            await login();
            return checkSessionStatus();
          }
          const errorMessage = `Error: ${response.status} ${response.statusText}`;
          throw new Error(errorMessage);
        }

        const responseData = await response.json();
        // console.log(responseData.gameSessionStatus.name)
        setSessionStatus(responseData);
      } catch (error) {
        console.error("Error updating session status:", error.message);
        // setError(error.message);
      }
    }
  }, [gameSessionData.gamesessionid, login]); // Ensure all relevant dependencies are included

  return { checkSessionStatus, sessionStatus };
};
