import { useState, useCallback, useContext, useRef, useEffect } from "react";
import { GlobalContext } from "../GlobalContext";
import useAuth from "./useAuth";
// import useLocalStorage from "./useLocalStorage";

export const useAbandonGame = () => {
  const [error, setError] = useState(null);
  const { gameSessionData } = useContext(GlobalContext);
  const { login } = useAuth();
  // const [storedValue] = useLocalStorage("token", null);
  const token = useRef(localStorage.getItem("token"));
  useEffect(() => {
    const updateToken = () => {
      token.current = localStorage.getItem("token");
    };
    window.addEventListener("storage", updateToken);
    return () => window.removeEventListener("storage", updateToken);
  }, []);

  const abandonGameSession = useCallback(
    async (gameName) => {
      if (token.current === null) {
        await login();
        return abandonGameSession(gameName);
      } else {
        const gameStatusIds = {
          puzzle: 5,
          firewall: 6,
          "crack-the-safe": 7,
        };

        try {
          // Call to set status as complete
          const patchStatusUpdate = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSession/${gameSessionData.gamesessionid}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
              body: JSON.stringify({
                gameSessionStatusId: 5, // 5 = abandoned
              }),
            }
          );

          if (!patchStatusUpdate.ok) {
            if (patchStatusUpdate.status === 401) {
              await login();
              return abandonGameSession(gameName);
            }
            throw new Error(
              `Error: ${patchStatusUpdate.status} ${patchStatusUpdate.statusText}`
            );
          } else {
            console.log("game has been marked as abandoned");
          }

          const postStatusAbandoned = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.current}`,
              },
              body: JSON.stringify({
                gameSessionId: gameSessionData.gamesessionid, // the current game session ID
                gameSessionEventTypeId: gameStatusIds[gameName], // this puzzle = 5, firewall = 6, crack-the-safe = 7, thegetaway = 8
                gameData: JSON.stringify({
                  RFIDTagId: gameSessionData.currentrfidtagid, // that user's RFID tag ID
                  Status: "Abandoned",
                }),
              }),
            }
          );

          if (!postStatusAbandoned.ok) {
            throw new Error(
              `Error: ${postStatusAbandoned.status} ${postStatusAbandoned.statusText}`
            );
          } else {
            console.log("game has been marked as abandoned");
            window.location.href = `/${gameName}`; // redirect to look for next player
          }
        } catch (error) {
          setError(error.message);
          console.error("Error during API calls:", error);
        }
      }
    },
    [gameSessionData.gamesessionid, login, gameSessionData.currentrfidtagid]
  );

  return { abandonGameSession, error };
};
